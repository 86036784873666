<template>
    <div>
        <app-header />
        <main>
            <div class="position-relative">
                <section class="section-shaped my-0 header-video">
                <vue-particles
                    color="#7367f0"
                    :particleOpacity="0.7"
                    :particlesNumber="60"
                    shapeType="polygon"
                    :particleSize="15"
                    linesColor="#dedede"
                    :linesWidth="1"
                    :lineLinked="true"
                    :lineOpacity="0.4"
                    :linesDistance="150"
                    :moveSpeed="3"
                    :hoverEffect="true"
                    hoverMode="grab"
                    :clickEffect="true"
                    clickMode="push"
                >
                </vue-particles>
                <!-- shape Hero -->
                    <div class="container header-container shape-container d-flex pb-5" style="padding-top: 12rem; margin-bottom:6.5rem;">
                        <div class="col px-0">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h1 class="h1 customer-header text-white">Get Feedback on Your
                                        <span class="scoller-content scoller-content__container">
                                            <div class="scoller-content__container">
                                                <ul class="scoller-content__container__list">
                                                <li class="scoller-content__container__list__item">Idea</li>
                                                <li class="scoller-content__container__list__item">Product</li>
                                                <li class="scoller-content__container__list__item">Startup</li>
                                                <li class="scoller-content__container__list__item">Or Just Advice</li>
                                                </ul>
                                            </div>
                                        </span>
                                    </h1>
                                    <p class="lead text-white" style="font-size: 1.4rem;">Hustleforge is a marketplace where people share ideas and get feedback from potential customers, experts in the same field, or people working on similar ideas or industries. Post your idea or product and start getting feedback today. </p>

                                    <div class="btn-wrapper text-center pt-5">
                                        <b-button
                                            @click="gotoSignUp"
                                            class="btn btn-neutral btn-icon btn-lg"
                                            variant="gradient-primary">
                                            Start Today
                                        </b-button>
                                    </div>
                                </div>
                                <div class="col-lg-auto col-md-12" style="width:500px; margin-top:-1rem">
                                    <!-- <b-embed
                                        type="iframe"
                                        aspect="4by3"
                                        src="https://player.vimeo.com/video/841415446"
                                        allowfullscreen
                                    ></b-embed> -->
                                  <div class="position-relative">
                                        <img src="startup.png" width="520px" class="img-center chatscreen">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- 1st Hero Variation -->
            </div>
            <!-- <section class="pt-lg-0 mt--200 mb-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <div class="row row-grid">
                                <div class="col-lg-6">
                                    <b-card class="border-0" hover shadow>
                                        <div class="d-flex">
                                            <b-avatar variant="primary" size="lg" shadow>
                                                <feather-icon size="22" icon="AwardIcon" />
                                            </b-avatar>
                                            <div class="pl-2 pt-1">
                                                <h4 class="text-primary font-weight-bold text-uppercase">Share your expertise</h4>
                                            </div>
                                        </div>
                                        <div>
                                            <ul class="list-unstyled mt-2">
                                                <li class="py-1">
                                                    <div class="d-flex align-items-center">
                                                        <feather-icon icon="CheckSquareIcon" size="32" class="mr-75"/>
                                                        <h4 class="mb-0 ml-2">Share your professional or personal expertise with users seeking your help.</h4>
                                                    </div>
                                                </li>
                                                <li class="py-1">
                                                    <div class="d-flex align-items-center">
                                                        <feather-icon icon="CheckSquareIcon" size="32" class="mr-75"/>
                                                        <h4 class="mb-0 ml-2">Create an income stream on your time with your expertise.</h4>
                                                    </div>
                                                </li>
                                                <li class="py-1">
                                                    <div class="d-flex align-items-center">
                                                        <feather-icon icon="CheckSquareIcon" size="32" class="mr-75"/>
                                                        <h4 class="mb-0 ml-2">Find opportunities to add value to startups, students, mentees and more .</h4>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-card>
                                </div>
                                <div class="col-lg-6">
                                    <b-card class="border-0" hover shadow>
                                        <div class="d-flex">
                                            <b-avatar variant="primary" size="lg" shadow>
                                                <feather-icon size="22" icon="SunriseIcon" />
                                            </b-avatar>
                                            <div class="pl-2 pt-1">
                                                <h4 class="text-primary font-weight-bold text-uppercase">Search for knowledge</h4>
                                            </div>
                                        </div>
                                        <div>
                                            <ul class="list-unstyled mt-2">
                                                <li class="py-1">
                                                    <div class="d-flex align-items-center">
                                                        <feather-icon icon="CheckSquareIcon" size="28" class="mr-75"/>
                                                        <h4 class="mb-0 ml-2">Search for your customers and seasoned professionals with a wide variety of experience to share.</h4>
                                                    </div>
                                                </li>
                                                <li class="py-1">
                                                    <div class="d-flex align-items-center">
                                                        <feather-icon icon="CheckSquareIcon" size="32" class="mr-75"/>
                                                        <h4 class="mb-0 ml-2">Read reviews, chat with your potential customers, and make connections.</h4>
                                                    </div>
                                                </li>
                                                <li class="py-1">
                                                    <div class="d-flex align-items-center">
                                                        <feather-icon icon="CheckSquareIcon" size="34" class="mr-75"/>
                                                        <h4 class="mb-0 ml-2">Validate your ideas, find a mentor, or prepare for a career transition (and more).</h4>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->
            <section class="section section-lg mt-4 mb-5">
                <div class="container">
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center">
                            <h2 class="display-3"><img src="hflogocom.png" class="img-center img-fluid w-50">
                                <span class="text-primary">Is a platform to Connect</span>
                            </h2>
                            <p class="lead">For those looking for extra income, who have expertise, or passionate about certain intrests. Users of Hustleforge can set their hours and monetize their extensive experience or interests by those seeking that expertise then browse, filter, and sort pofiles. This is all done on a secure, simple platform that makes the process easy!</p>
                            <div class="row">
                                <div class="col-xl-4 col-lg-6 mb-4 text-left">
                                    <b-link to="/profile/Ken">
                                        <b-card no-body class="card-apply-job">
                                            <b-card-header class="pb-1">
                                            <b-media no-body>
                                                <b-media-aside class="mr-1">
                                                <b-avatar
                                                    size="42"
                                                    src="https://firebasestorage.googleapis.com/v0/b/ideabox-335103.appspot.com/o/profiles%2FKen?alt=media&token=4729777d-f7e1-41a8-ba5a-e9f067b7ff72"
                                                />
                                                </b-media-aside>
                                                <b-media-body text-left>
                                                    <h5 class="mb-0"> Ken M</h5>
                                                    <b-badge pill variant="light-primary">Hospital & Health Care</b-badge>
                                                    <!-- <small class="text-muted">Updated 12m ago</small> -->
                                                </b-media-body>
                                            </b-media>
                                            </b-card-header>
                                            <b-card-body>
                                            <h6 class="apply-job-title">
                                                <i>Seasoned healthcare executive and strategist. Father. Husband. Tequila aficionado.</i>
                                            </h6>
                                            <b-card-text class="mb-2">
                                                I am a passionate healthcare leader and aspiring entrepreneur, with 10+ years in healthcare administration. I have experience working in inpatient...
                                            </b-card-text>
                                            <div class="apply-job-package bg-light-primary rounded">
                                                <div>
                                                <sup class="text-body"><small>$</small></sup>
                                                <h2 class="d-inline mr-25">50</h2>
                                                <sub class="text-body"><small>/ Hour</small></sub>
                                                </div>
                                                <b-badge variant="light-primary" pill> Expert </b-badge>
                                            </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-link>
                                </div>
                                <div class="col-xl-4 col-lg-6 mb-4 text-left">
                                    <b-link to="/profile/nancyburrows">
                                        <b-card no-body class="card-apply-job">
                                            <b-card-header class="pb-1">
                                            <b-media no-body>
                                                <b-media-aside class="mr-1">
                                                <b-avatar
                                                    size="42"
                                                    src="https://firebasestorage.googleapis.com/v0/b/ideabox-335103.appspot.com/o/profiles%2Fnancyburrows?alt=media&amp;token=0bd0094c-7de8-4c93-b691-e4989f36d85c"
                                                />
                                                </b-media-aside>
                                                <b-media-body text-left>
                                                    <h5 class="mb-0"> Nancy B</h5>
                                                    <b-badge pill variant="light-primary">Accounting</b-badge>
                                                    <!-- <small class="text-muted">Updated 12m ago</small> -->
                                                </b-media-body>
                                            </b-media>
                                            </b-card-header>
                                            <b-card-body>
                                            <h6 class="apply-job-title">
                                                <i>Revenue Accounting CPA, MBA with 12 years of experience in technology, public accounting and start ups.</i>
                                            </h6>
                                            <b-card-text class="mb-2">
                                                I started my career in public accounting an auditor in a variety of different industries. In 2015, I started working at Microsoft where I supported the...
                                            </b-card-text>
                                            <div class="apply-job-package bg-light-primary rounded">
                                                <div>
                                                <sup class="text-body"><small>$</small></sup>
                                                <h2 class="d-inline mr-25">30</h2>
                                                <sub class="text-body"><small>/ Hour</small></sub>
                                                </div>
                                                <b-badge variant="light-primary" pill> Expert</b-badge>
                                            </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-link>
                                </div>
                                <div class="col-xl-4 col-lg-6 mb-4 text-left">
                                    <b-link to="/profile/Ltran92">
                                        <b-card no-body class="card-apply-job">
                                            <b-card-header class="pb-1">
                                            <b-media no-body>
                                                <b-media-aside class="mr-1">
                                                <b-avatar
                                                    size="42"
                                                    src="https://firebasestorage.googleapis.com/v0/b/ideabox-335103.appspot.com/o/profiles%2FLtran92?alt=media&token=a438d181-fef4-4a38-baf8-6f57f088f5f5"
                                                />
                                                </b-media-aside>
                                                <b-media-body text-left>
                                                    <h5 class="mb-0"> Lauren T</h5>
                                                    <b-badge pill variant="light-primary">Marketing & Advertising</b-badge>
                                                    <!-- <small class="text-muted">Updated 12m ago</small> -->
                                                </b-media-body>
                                            </b-media>
                                            </b-card-header>
                                            <b-card-body>
                                            <h6 class="apply-job-title">
                                                <i>Looking to learn more about specific topics in Marketing.</i>
                                            </h6>
                                            <b-card-text class="mb-2">
                                                I want to learn more about marketing a business through social media outlets.
                                            </b-card-text>
                                            <div class="apply-job-package bg-light-primary rounded">
                                                <div>
                                                <sub class="text-body"><small>Looking for Advice</small></sub>
                                                </div>
                                                <b-badge variant="light-primary" pill> Seeker </b-badge>
                                            </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-link>
                                </div>
                            </div>
                            <div class="btn-wrapper">
                                <b-button
                                    @click="gotoSignUp"
                                    class="btn btn-neutral btn-icon btn-lg"
                                    variant="gradient-primary">
                                    Start Now
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section section-shaped my-0 overflow-hidden pt-5">
                <div class="shape shape-style-1 bg-gradient-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container py-0">
                    <div class="row row-grid align-items-center">
                        <div class="col-md-5 order-lg-2 ml-lg-auto">
                            <div class="position-relative pl-md-5">
                                <div class="transform-perspective-right">
                                    <img src="communication.png" class="img-center chatscreen">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 order-lg-1 mt-2">
                            <div class="d-flex">
                                <h3 class="display-3 text-white">Get real advice from real people
                                    <span class="text-primary mt-1" style="font-style: italic;font-size: smaller;">Start your conversation today</span>
                                </h3>
                            </div>

                            <div class="d-flex">
                                <div>
                                    <p class="lead text-white">
                                        <!-- <icon name="ni ni-settings" size="sm" gradient="white" shadow round color="primary"></icon> -->
                                        <strong>1. Create an account:</strong>  Setup your profile to describe your idea and what you are looking for.
                                    </p>
                                    <p class="lead text-white mt-1"><strong>2. Find a match:</strong> Browse profiles that match your needs, or wait, and we'll make an introduction!</p>
                                    <p class="lead text-white"><strong>3. Connect:</strong> Chat with others, explain your requirements, and agree on a meeting time.</p>
                                    <p class="lead text-white"><strong>4. Meet:</strong> Work with them and have your idea validated or questions answered.</p>
                                </div>
                            </div>
                            <div class="btn-wrapper text-center pt-5">
                                <b-button
                                    @click="gotoSignUp"
                                    class="btn btn-neutral btn-icon btn-lg"
                                    variant="gradient-primary">
                                    Start Now
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-lg section-shaped overflow-hidden my-0">
                <div class="shape shape-style-1 bg-gradient-default shape-skew2">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container py-0 mt-5 pb-5">
                    <div class="row row-grid justify-content-between align-items-center">
                        <div class="col-lg-6 mb-lg-auto">
                            <div class="transform-perspective-left mt-5">
                                <img src="experts.png" class="img-center img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h3 class="display-3 text-white">Help others with your knowledge and experience
                                <span class="text-primary mt-1" style="font-style: italic;font-size: smaller;">Get paid for your skills and experience</span>
                            </h3>
                            <p class="lead text-white">
                                <!-- <icon name="ni ni-settings" size="sm" gradient="white" shadow round color="primary"></icon> -->
                                <strong>1. Create an profile:</strong> Indicate your experience, skillset, rates, and schedule.
                            </p>
                            <p class="lead text-white"><strong>2. Match:</strong> We will match you with individuals seeking your expertise.</p>
                            <p class="lead text-white"><strong>3. Meet:</strong> Assist startups, students, marketers, peers, board members, and others at your rate on your schedule. Showcase your work and expertise or find unique projects, contracts, investments, and opportunities.</p>
                             <div class="btn-wrapper text-center pt-5">
                                <b-button
                                    @click="gotoSignUp"
                                    class="btn btn-neutral btn-icon btn-lg"
                                    variant="gradient-primary">
                                    Start Now
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- <section class="section section-sm" id="waitlist">
                <div class="container pt-sm pb-200">
                    <div class="row text-center justify-content-center">
                        <div class="col-lg-10">
                            <h2 class="display-3">Join the Waitlist</h2>
                            <p class="lead">We are actively building and can't wait to show you what we have in store. Please join our waitlist to let us know you're interested. </p>
                        </div>
                    </div>
                </div>
            </section> -->
            <!-- <section class="section section-lg pt-lg-0 section-contact-us">
                <div class="container">
                    <div class="row justify-content-center mt--200">
                        <div class="col-lg-8">
                            <b-card gradient="secondary" shadow body-classes="p-lg-5">
                                <h4 class="mb-1">Join Now</h4>

                                <form id="wait-list-form" @submit="checkForm">
                                <p v-if="errors.length">
                                    <b class="text-danger">Please correct the following error(s):</b>
                                    <ul>
                                    <li v-for="error in errors" :key="error" class="text-danger">{{ error }}</li>
                                    </ul>
                                </p>

                                <b-form-group
                                    label="Full Name"
                                    label-for="Fullname"
                                    >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="full name"
                                        rules="required"
                                    >
                                        <b-form-input
                                        id="fullname"
                                        v-model="name"
                                        :state="errors.length > 0 ? false:null"
                                        name="register-fullname"
                                        placeholder="john doe"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group
                                    label="Email"
                                    label-for="email"
                                    >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="email"
                                        rules="required|email"
                                    >
                                        <b-form-input
                                        id="email"
                                        v-model="email"
                                        :state="errors.length > 0 ? false:null"
                                        name="register-email"
                                        placeholder="john@example.com"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group
                                    label="I'm interested in:"
                                    label-for="expert"
                                    >
                                    <select class="form-control" v-model="expert" id="expert">
                                        <option>Sharing my expertise</option>
                                        <option>Finding an expert</option>
                                        <option>Both</option>
                                    </select>
                                </b-form-group>

                                <b-form-group
                                    label-for="description"
                                    class="mb-4"
                                    >
                                    <textarea class="form-control form-control-alternative" v-model="description" id="description" name="description" rows="4"
                                                cols="80" placeholder="Tell us what your looking for"></textarea>
                                </b-form-group>

                                <input type="submit" class="btn btn-block btn-primary btn-lg" round value="Join">
                                </form>
                            </b-card>
                        </div>
                    </div>
                </div>
            </section> -->
            <section class="section section-lg">
                <div class="container">
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-10 text-center">
                            <h2 class="display-3">FAQ</h2>
                            <p class="lead"> Find the answers for the most frequently asked questions below </p>
                        </div>
                    </div>

                    <div class="accordion" id="faqExample">
                        <div class="card">
                            <div class="card-header-landing p-2" id="headingOne">
                                <h3 class="mb-0">
                                   What is the best way to use Hustleforge?
                                    <!-- <i class="ni ni-bold-down float-right btn btn-link"></i> -->
                                </h3>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#faqExample">
                                <div class="card-body">
                                    <p>Hustleforge is typically used in a few key ways:</p>
                                    <ul>
                                        <li>Mentorship - finding or offering mentorship to students, peers, or new businesses/leadership teams in your field of expertise</li>
                                        <li>A side hustle - offering your knowledge and experience to those searching for your specific expertise</li>
                                        <li>Startup Validation - validate your startup idea or the problem you are trying to solve with those with specific knowledge of your target market</li>
                                        <li>Career change - find and engage other professionals in discussions about their career and find expertise/guidance to make a career change of your own (or advise others on their career changes)</li>
                                        <li>Other - personal development, life coaching, complex decision making, general advice, and more</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header-landing p-2" id="headingTwo">
                            <h3 class="mb-0">
                            How much does it cost? Can it be free?
                            </h3>
                        </div>
                        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#faqExample">
                            <div class="card-body">
                                <p>Our independent experts can set their rates for different purposes. For instance, there may be a rate for speaking with a student, a rate for business advising, a rate for micro-consulting, etc.</p>
                                <p>Experts/Customers can offer some or all of their services for free if they choose.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header-landing p-2" id="headingThree">
                            <h3 class="mb-0">
                                What is an Expert?
                            </h3>
                        </div>
                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#faqExample">
                            <div class="card-body">
                                <p>Anyone with any significant knowledge of a problem, opportunity, or industry you might want to learn from. Typically, 5 years (10,000 hours) is a good gauge of experience to qualify someone as an ‘expert’.</p>
                                <p>This might be a nuclear engineer with 15 years of professional experience, but it also might be a gamer with 10 years of experience gaming, a coffee enthusiast who’s been drinking coffee for 20 years, or more. </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header-landing p-2" id="headingThree">
                            <h3 class="mb-0">
                                What if I am not an Expert?
                            </h3>
                        </div>
                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#faqExample">
                            <div class="card-body">
                                <p>That’s ok, there’s still a place for you at Hustleforge! People are searching for all sorts of expertise, even if you only have a few days, weeks or months of it. Have an interest in sports, poker or disc golf, but aren't a pro? That’s ok - list it as an interest on your profile and users can book your time to talk about your interests!</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header-landing p-2" id="headingThree">
                            <h3 class="mb-0">
                                How is this different from Upwork, Fiverr, or LinkedIn?
                            </h3>
                        </div>
                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#faqExample">
                            <div class="card-body">
                                <p>Upwork and Fiverr are geared toward full time gig work. While Hustleforge can also create opportunities for those seeking gig work, our primary goal is to make time and meaningful connections between professionals and those needing their expertise.</p>
                                <p>LinkedIn does help create connections like Hustleforge, but the busy people on LinkedIn are the same ones that it's hard to get in front of in person. Busy professionals are active everywhere, and getting the time and good feedback from LinkedIn connections can take time.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header-landing p-2" id="headingFour">
                            <h3 class="mb-0">
                                Why was Hustleforge built?
                            </h3>
                        </div>
                        <div id="collapseFour" class="collapse show" aria-labelledby="headingFour" data-parent="#faqExample">
                            <div class="card-body">
                                <p>Hustleforge is the answer to a problem the two founders have experienced personally.</p>
                                <p>When attempting to build a startup and find an MVP, they found connecting with professionals in their target market very difficult. Everyone was busy, had too many distractions, and it took more work to get face time with decision-makers. No one had time, and very few people wanted to talk to an unknown person.</p>
                                <p>Even worse, existing processes for validating startup ideas need to be revised for part-time side hustlers. It's hard to reach your target market when you're at your day job!</p>
                                <p>This is why we built Hustleforge: to introduce side hustlers, students, and people needing expertise with willing and available experts. These experts can share their extensive knowledge, develop their brand, and create a revenue stream while making their extensive knowledge accessible to everyone!</p>
                            </div>
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center mt-5">
                        <div class="btn-wrapper text-center">
                            <b-button
                                @click="gotoSignUp"
                                class="btn btn-neutral btn-icon btn-lg"
                                variant="gradient-primary">
                                Start Now
                            </b-button>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Modal -->
            <div class="modal fade" id="waitlistModalSucess" tabindex="-1" role="dialog" aria-labelledby="waitlistModalSucess" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="waitlistModalSucessTitle">Welcome to the Waitlist</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <!-- <span aria-hidden="true">&times;</span> -->
                            </button>
                        </div>
                        <div class="modal-body">
                            Welcome to our waitlist. We are excited you've shown interest in our community. We will notify you by email once we have availability. Thanks again, the Hustleforge Team!
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <app-footer />
    </div>
</template>

<script>
import { getFirestore, collection, addDoc } from "firebase/firestore";
const db = getFirestore()

import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BCard,
  BCardText,
  BAvatar,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BBadge,
  BCardBody,
  BLink,
  BEmbed
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import AppFooter from './AppFooter.vue'
import AppHeader from './AppHeader.vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormTextarea,
        BCard,
        BCardText,
        BAvatar,
        ValidationProvider,
        ValidationObserver,
        AppFooter,
        AppHeader,
        BImg,
        BMedia,
        BMediaAside,
        BMediaBody,
        BCardHeader,
        BBadge,
        BCardBody,
        BLink,
        BEmbed
    },
    name: "home",
    title: 'Hustleforge',
    data: function(){
        return {
            errors: [],
            name: null,
            email: null,
            expert: null,
            description: null,

            // validation rules
            required,
            email,
        }
    },
    methods: {
        gotoSignUp(){
            this.$router.push({ name: 'auth-register'})
        },
        scrollToWaitlist() {
            const element = document.getElementById('waitlist')
            element.scrollIntoView({ behavior: 'smooth' })
        },
        async addwaitlist(){
            try {
                const docRef = await addDoc(collection(db, "waitlist"), {
                    name: this.name,
                    email: this.email,
                    type: this.expert,
                    description: this.description,
                    time: new Date(),
                });
                //console.log("Document written with ID: ", docRef.id);
                document.getElementById("waitlistModalSucess").style.display = "block"
                document.getElementById("waitlistModalSucess").classList.add("show")
            } catch (e) {
               console.error("Error adding document: ", e);
            }

            this.name= ''
            this.email = ''
            this.expert = ''
            this.description = ''
        },
        checkForm: function (e) {
            e.preventDefault();

            // Get the modal
            var modal = document.getElementById('waitlistModalSucess');

            // When the user clicks anywhere outside of the modal, close it
            window.onclick = function(event) {
                if (event.target == modal) {
                    document.getElementById("waitlistModalSucess").style.display = "none"
                    document.getElementById("waitlistModalSucess").classList.remove("show")
                }
            }

            if (this.name && this.email && this.description) {
                this.addwaitlist();
            }

            this.errors = [];

            if (!this.name) this.errors.push('Name required.');
            if (!this.email) this.errors.push('Email required.');
            if (!this.description) this.errors.push('Tell us what your looking for');
        },
    }
};
</script>
